import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {
  faBars,
  faLaughBeam,
  faPaperPlane,
  faShareAlt,
  faCopy,
  faEnvelope,
  faLock,
  faExpand,
  faCompress,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  fab,
  faBars,
  faLaughBeam,
  faPaperPlane,
  faShareAlt,
  faCopy,
  faEnvelope,
  faLock,
  faExpand,
  faCompress
)
